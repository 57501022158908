var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"nav"},[_c('Breadcrumb',[_c('BreadcrumbItem',[_c('span',{staticClass:"breadcrumb__item",on:{"click":_vm.onBack}},[_vm._v("新增")])]),_c('BreadcrumbItem',[_vm._v(" "+_vm._s(_vm.type === "add" ? "添加地址" : "编辑地址")+" ")])],1)],1),_c('div',{staticClass:"card__container"},[_c('div',{staticClass:"container__title"},[_vm._v(" "+_vm._s(_vm.type === "add" ? "添加新地址" : "编辑地址")+" ")]),_c('a-form',{staticClass:"advanced-search-form",attrs:{"form":_vm.form}},[_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"所在地区"}},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'areaCode',
                { rules: [{ required: true, message: '请输入所在地区' }] }
              ]),expression:"[\n                'areaCode',\n                { rules: [{ required: true, message: '请输入所在地区' }] }\n              ]"}],attrs:{"options":_vm.areaTreeData,"field-names":_vm.areaTreeNames,"placeholder":"请选择区域"},on:{"change":_vm.onChangeArea}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"详细地址"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'detailAddress',
                {
                  rules: [{ required: true, message: '请输入详细地址' }]
                }
              ]),expression:"[\n                'detailAddress',\n                {\n                  rules: [{ required: true, message: '请输入详细地址' }]\n                }\n              ]"}],attrs:{"placeholder":"请输入详细地址"}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"联系人姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'name',
                {
                  rules: [{ required: true, message: '请输入联系人姓名' }]
                }
              ]),expression:"[\n                'name',\n                {\n                  rules: [{ required: true, message: '请输入联系人姓名' }]\n                }\n              ]"}],attrs:{"placeholder":"请输入联系人姓名"}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"联系人电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'tel',
                {
                  rules: [{ required: true, message: '请输入联系人电话' }]
                }
              ]),expression:"[\n                'tel',\n                {\n                  rules: [{ required: true, message: '请输入联系人电话' }]\n                }\n              ]"}],attrs:{"placeholder":"请输入联系人电话"}})],1)],1)],1),_c('a-button',{staticClass:"btn-save",attrs:{"type":"primary"},on:{"click":_vm.onSave}},[_vm._v(" 保存 ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
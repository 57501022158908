<template>
  <div>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>
          <span class="breadcrumb__item" @click="onBack">新增</span>
        </BreadcrumbItem>
        <BreadcrumbItem>
          {{ type === "add" ? "添加地址" : "编辑地址" }}
        </BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="card__container">
      <div class="container__title">
        {{ type === "add" ? "添加新地址" : "编辑地址" }}
      </div>
      <a-form :form="form" class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="所在地区">
              <a-cascader
                v-decorator="[
                  'areaCode',
                  { rules: [{ required: true, message: '请输入所在地区' }] }
                ]"
                :options="areaTreeData"
                :field-names="areaTreeNames"
                placeholder="请选择区域"
                @change="onChangeArea"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="详细地址">
              <a-input
                v-decorator="[
                  'detailAddress',
                  {
                    rules: [{ required: true, message: '请输入详细地址' }]
                  }
                ]"
                placeholder="请输入详细地址"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="联系人姓名">
              <a-input
                v-decorator="[
                  'name',
                  {
                    rules: [{ required: true, message: '请输入联系人姓名' }]
                  }
                ]"
                placeholder="请输入联系人姓名"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="联系人电话">
              <a-input
                v-decorator="[
                  'tel',
                  {
                    rules: [{ required: true, message: '请输入联系人电话' }]
                  }
                ]"
                placeholder="请输入联系人电话"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-button type="primary" class="btn-save" @click="onSave">
          保存
        </a-button>
      </a-form>
    </div>
  </div>
</template>

<script>
import BreadcrumbItem from "@/components/BreadcrumbItem.vue";
import { loadArea } from "@/services/DealerService";
import Breadcrumb from "@/components/Breadcrumb.vue";

export default {
  components: { Breadcrumb, BreadcrumbItem },
  data() {
    return {
      form: this.$form.createForm(this),
      areaTreeData: [],
      areaTreeNames: {
        label: "areaName",
        value: "areaCode",
        children: "subAreas"
      },
      type: this.$route.params.type,
      selectedOptions: []
    };
  },
  mounted() {
    this.loadAreaData();
  },
  methods: {
    // 加载行政区数据
    loadAreaData() {
      loadArea().then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.areaTreeData = resp.data.data || [];
          if (this.$store.state.addressInfo && this.type === "edit") {
            this.loadAddressData();
          }
        }
      });
    },
    // 加载地址数据
    loadAddressData() {
      console.log(this.$store.state.addressInfo);
      let addressInfo = this.$store.state.addressInfo;
      this.form.setFieldsValue({
        areaCode: [
          addressInfo.provinceCode,
          addressInfo.cityCode,
          addressInfo.countyCode
        ],
        detailAddress: addressInfo.detailAddress,
        name: addressInfo.name,
        tel: addressInfo.tel
      });
      this.selectedOptions = [
        {
          areaName: addressInfo.provinceName,
          areaCode: addressInfo.provinceCode
        },
        {
          areaName: addressInfo.cityName,
          areaCode: addressInfo.cityCode
        },
        {
          areaName: addressInfo.countyName,
          areaCode: addressInfo.countyCode
        }
      ];
    },
    // 返回
    onBack() {
      this.$router.back();
    },
    // 切换区域
    onChangeArea(value, selectedOptions) {
      this.selectedOptions = selectedOptions;
    },
    // 保存
    onSave() {
      this.form.validateFields((err, values) => {
        if (!err) {
          let addressItem = {
            id: this.type === "add" ? "1" : this.$store.state.addressInfo.id,
            name: values.name,
            tel: values.tel,
            isDefault:
              this.type === "add"
                ? false
                : this.$store.state.addressInfo.isDefault,
            address: this.dealAreaName(values),
            addressItem: this.dealAddress(values),
            provinceName: this.selectedOptions[0].areaName,
            provinceCode: this.selectedOptions[0].areaCode,
            countyName: this.selectedOptions[2].areaName,
            countyCode: this.selectedOptions[2].areaCode,
            cityName: this.selectedOptions[1].areaName,
            cityCode: this.selectedOptions[1].areaCode,
            detailAddress: values.detailAddress
          };
          this.$store.state.addressInfo = addressItem;
          this.$router.back();
        }
      });
    },
    // 处理省市区areaName
    dealAreaName(values) {
      let str = "";
      if (this.selectedOptions.length > 0) {
        if (
          this.selectedOptions[0].areaName === this.selectedOptions[1].areaName
        ) {
          str = `${this.selectedOptions[0].areaName} ${this.selectedOptions[2].areaName} ${values.detailAddress}`;
        } else {
          str = `${this.selectedOptions[0].areaName} ${this.selectedOptions[1].areaName} ${this.selectedOptions[2].areaName} ${values.detailAddress}`;
        }
      } else {
        str = `${values.detailAddress}`;
      }
      return str;
    },
    // 处理address
    dealAddress(values) {
      let str = this.dealAreaName(values);
      return `${str}，${values.name}，${values.tel}`;
    }
  }
};
</script>

<style scoped>
.container__title {
  margin-bottom: 20px;
}

.breadcrumb__item {
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
}

.breadcrumb__item:hover {
  color: #1890ff;
}
</style>
